import React, { useState } from 'react';
import './FieldNumber.scss';
import TextFieldCustom from '../../TextFieldCustom/TextFieldCustom';
import { NumericFormat, PatternFormat } from 'react-number-format';
import ErrorsField from '../../ErrorsField/ErrorsField';
import { useUser } from '../../../services/userContext';
import { Typography } from '@mui/material';



const FieldNumber = (props) => {
  const {field,onChange} = props
  const {isDebug} = useUser()

  const [errors,setErrors] = useState(field.errors)

  const handleChange = (value) => {
    let newValue = value
    

    if(field.type === 'tel'){
      newValue = value.formattedValue
    }else{
      newValue = value.floatValue
    }

    const length = value?.floatValue?.toString()?.length || 0
    if(value.floatValue < 0){
      field.errors = ['Le champ doit être positif']
    }else if(field.maxlength && field.maxlength < length){
      field.errors = [`Le champ ne doit pas dépasser ${field.maxlength} chiffres`]
    }else{
      field.errors = null
    }

    if(value.value === '' || value === undefined){
      newValue = null;
    }


    setErrors(field.errors)
    onChange(field.name,newValue,field.type === 'tel' ? 'string' : 'number')
  }

  return (<>
    {isDebug && field.type + ' - ' + field.name + ':' + field.value + ' '+typeof field.value}
    <TextFieldCustom isRequired={field.isRequired} type="number" value={field.value ? parseInt(field.value).toLocaleString() + ' €' : ''} name={field.name} label={field.label} fullWidth readOnly={field.read_only ? true : false} isError={field.errors ? true : false} optional={field.optional}>
      {field.type !== 'tel' && <NumericFormat thousandSeparator=" " suffix={field.type === 'money' ? ' €' : ''} onValueChange={(e) => handleChange(e)} value={field.value} />}
      {field.type === 'tel' && <PatternFormat format='(#)#.##.##.##.##' onValueChange={(e) => handleChange(e)} value={field.value} />}
    </TextFieldCustom>
    {field.help && <Typography>{field.help}</Typography>}
    <ErrorsField field={field} errors={errors} />
    </>
  )
};

export default FieldNumber;